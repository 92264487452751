import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
import AccumulationPaymentDeductIframe from '@/views/contents/member/accumulation/AccumulationPaymentDeductIframe.vue';
import AccumulationSettingIframe from '@/views/contents/member/accumulation/AccumulationSettingIframe.vue';
import MemberGroup from '@/views/contents/member/basic/MemberGroup.vue';
// import MemberGrade from '@/views/contents/member/basic/MemberGrade.vue';
import MemberRegistration from '@/views/contents/member/basic/MemberRegistration.vue';
import MailIframe from '@/views/contents/member/email/MailIframe.vue';
import EmailAutoTemplate from '@/views/contents/member/email/EmailAutoTemplate.vue';
import EmailHistory from '@/views/contents/member/email/EmailHistory.vue';
import NotificationMainIframe from '@/views/contents/member/kakao/NotificationMainIframe.vue';
import NotificationMain from '@/views/contents/member/kakao/NotificationMain.vue';
import NotificationConfig from '@/views/contents/member/kakao/NotificationConfig.vue';
import NotificationTemplate from '@/views/contents/member/kakao/NotificationTemplate.vue';
import NotificationHistory from '@/views/contents/member/kakao/NotificationHistory.vue';
// import Reject080MainIframe from '@/views/contents/member/sms/Reject080MainIframe.vue';
import Reject080ConfigIframe from '@/views/contents/member/sms/Reject080ConfigIframe.vue';
import Reject080ListIframe from '@/views/contents/member/sms/Reject080ListIframe.vue';
import SmsContentsIframe from '@/views/contents/member/sms/SmsContentsIframe.vue';
import MemberRegisterIframe from '@/views/contents/member/basic/MemberRegisterIframe.vue';
import MemberEditIframe from '@/views/contents/member/basic/MemberEditIframe.vue';
import MemberListIframe from '@/views/contents/member/basic/MemberListIframe.vue';
import WithdrawalMemberIframe from '@/views/contents/member/basic/WithdrawalMemberIframe.vue';
import DormantMemberIframe from '@/views/contents/member/basic/DormantMemberIframe.vue';
import MemberGradeIframe from '@/views/contents/member/basic/MemberGradeIframe.vue';
import CollectionSettingIframe from '@/views/contents/member/basic/CollectionSettingIframe.vue';
import CrmGroupList from '@/views/contents/aggregator/CrmGroupList.vue';
import CrmGroupCreate from '@/views/contents/aggregator/CrmGroupCreate.vue';

export default (): RouteConfig => {
  return {
    path: '/member',
    name: 'member',
    component: DefaultLayout,
    children: [
      {
        path: 'accumulation/payment-deduct',
        name: 'AccumulationPaymentDeductIframe',
        component: AccumulationPaymentDeductIframe,
      },
      {
        path: 'accumulation/setting',
        name: 'AccumulationSettingIframe',
        component: AccumulationSettingIframe,
      },
      {
        path: 'basic/dormant',
        name: 'DormantMemberIframe',
        component: DormantMemberIframe,
      },
      {
        path: 'basic/list',
        name: 'MemberListIframe',
        component: MemberListIframe,
      },
      // {
      //   path: 'basic/grade',
      //   name: 'MemberGrade',
      //   component: MemberGrade,
      // },
      {
        path: 'basic/grade',
        name: 'MemberGradeIframe',
        component: MemberGradeIframe,
      },
      {
        path: 'basic/group',
        name: 'MemberGroup',
        component: MemberGroup,
      },
      {
        path: 'basic/register',
        name: 'MemberRegistration',
        component: MemberRegistration,
        redirect: { name: 'MemberRegister' },
        children: [
          {
            path: '/',
            name: 'MemberRegister',
            component: MemberRegisterIframe,
          },
        ],
      },
      {
        path: 'basic/edit',
        name: 'MemberEdit',
        component: MemberEditIframe,
        meta: {
          breadcrumb: ['회원', '회원관리', '회원 수정'],
        },
      },
      {
        path: 'basic/withdrawal',
        name: 'WithdrawalMemberIframe',
        component: WithdrawalMemberIframe,
        // meta: {
        //   gridName: 'PRO_MEMBER_EXPEL_GRID',
        // },
      },
      {
        path: 'basic/collection-setting',
        name: 'CollectionSettingIframe',
        component: CollectionSettingIframe,
        meta: {
          breadcrumb: ['회원', '회원관리', '회원가입 항목 관리'],
        },
      },
      {
        path: 'email/manage',
        name: 'AutoEmail',
        component: MailIframe,
        redirect: { name: 'EmailConfig' },
        children: [
          {
            path: 'config',
            name: 'EmailConfig',
            component: MailIframe,
            meta: {
              detection: true,
              detectConfirm: true,
            },
          },
          {
            path: 'template',
            name: 'EmailAutoTemplate',
            component: EmailAutoTemplate,
            meta: {
              detection: true,
              detectConfirm: true,
            },
          },
          {
            path: 'history',
            name: 'EmailHistory',
            component: EmailHistory,
          },
        ],
      },
      {
        path: 'kakao/notification-prev',
        name: 'NotificationMain',
        component: NotificationMain,
        redirect: { name: 'NotificationConfig' },
        children: [
          {
            path: 'config',
            name: 'NotificationConfig',
            component: NotificationConfig,
            meta: {
              detection: true,
            },
          },
          {
            path: 'template',
            name: 'NotificationTemplate',
            component: NotificationTemplate,
          },
          {
            path: 'history',
            name: 'NotificationHistory',
            component: NotificationHistory,
          },
        ],
      },
      {
        path: 'kakao/notification',
        name: 'NotificationMain',
        component: NotificationMainIframe,
        redirect: { name: 'NotificationMainIframe' },
        children: [
          {
            path: 'config',
            name: 'NotificationMainIframe',
            component: NotificationMainIframe,
          },
          {
            path: 'template',
            name: 'NotificationMainIframe',
            component: NotificationMainIframe,
          },
          {
            path: 'history',
            name: 'NotificationMainIframe',
            component: NotificationMainIframe,
          },
        ],
      },
      {
        // 통합어드민 080 수신거부 관리
        path: 'sms/reject',
        name: 'Reject080Main',
        redirect: { path: 'sms/reject/config' },
      },
      {
        path: 'sms/reject/config',
        name: 'Reject080Config',
        component: Reject080ConfigIframe,
      },
      {
        path: 'sms/reject/list',
        name: 'Reject080List',
        component: Reject080ListIframe,
      },
      {
        // 통합어드민 SMS관리
        path: 'sms/main',
        name: 'SmsMain',
        component: SmsContentsIframe,
        redirect: { name: 'SmsConfig' },
        children: [
          {
            path: 'config',
            name: 'SmsConfig',
            component: SmsContentsIframe,
            meta: {
              detection: true,
              detectConfirm: true,
              iframePath: '/member/sms/main/config',
            },
          },
          {
            path: 'send',
            name: 'SmsSend',
            component: SmsContentsIframe,
            meta: {
              detection: true,
              detectConfirm: true,
              iframePath: '/member/sms/main/send',
            },
          },
          {
            path: 'automation',
            name: 'SmsAutomation',
            component: SmsContentsIframe,
            meta: {
              detection: true,
              iframePath: '/member/sms/main/automation',
            },
          },
          {
            path: 'history',
            name: 'SmsHistory',
            component: SmsContentsIframe,
            meta: {
              iframePath: '/member/sms/main/history',
            },
          },
          {
            path: 'reservation',
            name: 'SmsReservation',
            component: SmsContentsIframe,
            meta: {
              iframePath: '/member/sms/main/reservation',
            },
          },
        ],
      },
      // aggregator
      {
        path: 'crm-group/list',
        name: 'CrmGroupList',
        component: CrmGroupList,
      },
      {
        path: 'crm-group/create',
        name: 'CrmGroupCreate',
        component: CrmGroupCreate,
      },
    ],
  };
};
