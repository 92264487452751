import DefaultLayout from '@/views/DefaultLayout.vue';
import ProductList from '@/views/contents/product/basic/ProductList.vue';
import ProductListIframe from '@/views/contents/product/basic/ProductListIframe.vue';
import ProductRegistration from '@/views/contents/product/basic/ProductRegistration.vue';
import ProductRegistrationIframe from '@/views/contents/product/basic/ProductRegistrationIframe.vue';
import ProductAdd from '@/views/contents/product/basic/ProductAdd.vue';
import ProductAddIframe from '@/views/contents/product/basic/ProductAddIframe.vue';
import CategoryDisplay from '@/views/contents/product/categorization/CategoryDisplay.vue';
import ProductDisplayOrderIframe from '@/views/contents/product/categorization/ProductDisplayOrderIframe.vue';
import BrandIframe from '@/views/contents/product/categorization/BrandIframe.vue';
// import IconList from '@/views/contents/product/management/IconList/IconList.vue';
import IconListIframe from '@/views/contents/product/management/IconList/IconListIframe.vue';
import StandardFee from '@/views/contents/product/management/StandardFee.vue';
import StandardFeeIframe from '@/views/contents/product/management/StandardFeeIframe.vue';
import ProductStock from '@/views/contents/product/stock/ProductStock.vue';
import ProductStockBulkManagementIframe from '@/views/contents/product/stock/BulkManagementIframe.vue';
import ProductRestockIframe from '@/views/contents/product/stock/ProductRestockIframe.vue';
import ProductRestockDetailIframe from '@/views/contents/product/stock/ProductRestockDetailIframe.vue';
import ProductBulkAdd from '@/views/contents/product/basic/ProductBulkAdd.vue';
import ProductBulkAddIframe from '@/views/contents/product/basic/ProductBulkAddIframe.vue';
import { ShopByRouteConfig } from '@/router/routes';
import ProductThumbnailRegistration from '@/views/contents/product/basic/ProductThumbnailRegistration.vue';
import CustomPropertiesIframe from '@/views/contents/product/basic/CustomPropertiesIframe.vue';
import ScreeningListIframe from '@/views/contents/product/basic/ScreeningListIframe.vue';

export default (): ShopByRouteConfig => {
  return {
    path: '/product',
    name: 'Product',
    component: DefaultLayout,
    children: [
      {
        path: 'basic/list-prev',
        name: 'ProductList',
        component: ProductList,
        meta: {
          excelCreateMenuKey: 'PRO_PRODUCT_LIST',
        },
      },
      {
        path: 'basic/list',
        name: 'ProductListIframe',
        component: ProductListIframe,
        meta: {
          excelCreateMenuKey: 'PRO_PRODUCT_LIST',
        },
      },
      {
        path: 'basic/management-prev',
        name: 'ProductRegistrationPrev',
        component: ProductRegistration,
      },
      {
        path: 'basic/management',
        name: 'ProductRegistration',
        component: ProductRegistrationIframe,
      },
      {
        path: 'basic/management/add-prev',
        name: 'ProductAddPrev',
        component: ProductAdd,
        meta: {
          // detection: true,
          breadcrumb: ['상품', '상품관리', '일반 상품 등록'],
        },
      },
      {
        path: 'basic/management/add',
        name: 'ProductAdd',
        component: ProductAddIframe,
        meta: {
          breadcrumb: ['상품', '상품관리', '일반 상품 등록'],
        },
      },
      {
        path: 'management/set/add',
        name: 'ProductAdd',
        component: ProductAddIframe,
        meta: {
          breadcrumb: ['상품', '상품관리', '세트 상품 등록'],
        },
      },
      {
        path: 'basic/management/edit-prev',
        name: 'ProductEditPrev',
        component: ProductAdd,
        meta: {
          // detection: true,
          breadcrumb: ['상품', '상품관리', '일반 상품 수정'],
        },
      },
      {
        path: 'basic/management/edit',
        name: 'ProductEdit',
        component: ProductAddIframe,
        meta: {
          breadcrumb: ['상품', '상품관리', '일반 상품 수정'],
        },
      },
      {
        path: 'management/set/edit',
        name: 'ProductEdit',
        component: ProductAddIframe,
        meta: {
          breadcrumb: ['상품', '상품관리', '세트 상품 수정'],
        },
      },
      {
        path: 'basic/management/copy-prev',
        name: 'ProductCopyPrev',
        component: ProductAdd,
        meta: {
          // detection: true,
          breadcrumb: ['상품', '상품관리', '일반 상품 복사'],
        },
      },
      {
        path: 'basic/management/copy',
        name: 'ProductCopy',
        component: ProductAddIframe,
        meta: {
          breadcrumb: ['상품', '상품관리', '일반 상품 복사'],
        },
      },
      {
        path: 'basic/management/bulk-prev',
        name: 'ProductBulkAdd',
        component: ProductBulkAdd,
        meta: {
          breadcrumb: ['상품', '상품관리', '대량 상품 등록'],
        },
      },
      {
        path: 'basic/management/bulk',
        name: 'ProductBulkAddIframe',
        component: ProductBulkAddIframe,
        meta: {
          breadcrumb: ['상품', '상품관리', '대량 상품 등록'],
        },
      },
      {
        path: 'basic/screening-list',
        name: 'ScreeningListIframe',
        component: ScreeningListIframe,
        meta: {
          breadcrumb: ['상품', '상품관리', '상품 심사'],
        },
      },
      {
        path: 'basic/thumbnails',
        name: 'ProductThumbnailRegistration',
        component: ProductThumbnailRegistration,
      },
      {
        path: 'basic/custom-properties',
        name: 'CustomPropertiesIframe',
        component: CustomPropertiesIframe,
      },
      {
        path: 'categorization/display',
        name: 'CategoryDisplay',
        component: CategoryDisplay,
        meta: {
          detection: true,
        },
      },
      {
        path: 'categorization/display-order',
        name: 'ProductDisplayOrderIframe',
        component: ProductDisplayOrderIframe,
      },
      {
        path: 'categorization/brand',
        name: 'Brand',
        component: BrandIframe,
      },
      {
        path: 'management/icon',
        name: 'IconList',
        component: IconListIframe,
      },
      {
        path: 'management/fee-prev',
        name: 'StandardFeePrev',
        component: StandardFee,
        meta: {
          detection: true,
        },
      },
      {
        path: 'management/fee',
        name: 'StandardFee',
        component: StandardFeeIframe,
      },
      {
        path: 'management/restock',
        name: 'ProductRestock',
        component: ProductRestockIframe,
      },
      {
        path: 'management/restock/detail',
        name: 'ProductRestockDetail',
        component: ProductRestockDetailIframe,
      },
      {
        path: 'bulk-management/stock',
        name: 'ProductStock',
        component: ProductStock,
        meta: {
          gridName: 'PRO_STOCK_MANAGEMENT_GRID',
        },
      },
      {
        path: 'bulk-management/edit/excel',
        name: 'ProductStockBulkManagementIframe',
        component: ProductStockBulkManagementIframe,
      },
    ],
  };
};
