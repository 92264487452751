






import Cookies from 'js-cookie';
import { SHOPBY_REFRESHTOKEN } from '@/const/cookie';

export default {
  computed: {
    remoteSrc() {
      const refreshToken = Cookies.get(SHOPBY_REFRESHTOKEN);
      const queryObject = {
        ...this.$route.query,
        hideLayout: 'true',
        hideFooter: 'true',
        solution: 'SHOPBY',
        code: refreshToken,
        clientLocation: location.href,
      };
      const queryString = new URLSearchParams(queryObject).toString();

      return process.env.VUE_APP_AGGREGATOR_URL + '/crm-group/list?' + queryString;
    },
  },
};
