// TODO
import { AdditionalService, Banner } from '@/types/addition';

import { i18n } from '@/main';

export const i18nAdditional = (word: string): string => i18n.t(`SERVICE.ADDITIONAL.${word}`).toString();

const { VUE_APP_GODO_API_URL } = process.env;

export const banners: Banner[] = [
  {
    img: 'promotion-banner-sms.jpg',
    title: 'SMS 문자발송',
    desc: '고객 안내는 필수, 첫 매출을 위해 SMS 포인트 미리 충전하세요',
    targetPath: VUE_APP_GODO_API_URL + '/echost/power/add/member/sms-intro.gd?link=gnb2_2',
  },
  // {
  //   img: 'promotion-banner-sms.jpg',
  //   title: '네이버 쇼핑',
  //   desc: '네이버 메인과 쇼핑검색 등\n네이버의 다양한 곳에 내 상품을 노출해보세요.',
  //   targetPath: VUE_APP_GODO_API_URL + '/echost/power/add/member/sms-intro.gd?link=gnb2_2',
  // },
];

/**
 * 1차 오픈 시 지정한 신규 부가서비스로 노출되며 오픈 이후 GOMS 연동 시 진열 관리 가능.
 * 따라서 1차 오픈 까지는 이처럼 FE 내부에서 배열로 관리하고 추 후 API 로 변경하도록 한다.
 */
export const additionalAllServices: AdditionalService[] = [
  {
    title: '결제/간편결제',
    desc: '쇼핑몰 매출 상승의 첫\n단추! 다양한\n결제 수단 연동',
    data: [
      {
        img: 'img_nhnkcp.png',
        alt: 'nhn KCP',
        title: 'PG - KCP',
        tags: ['#결제/간편결제', '#바로오픈', '#휴대폰수수료최저'],
        remoteCode: 'service_pg_info',
        applyCode: 'service_pg_kcp',
      },
      {
        img: 'img_toss.png',
        alt: 'toss payments',
        title: 'PG - 토스페이먼츠',
        tags: ['#결제/간편결제', '#바로오픈', '#빠른 정산주기'],
        remoteCode: 'service_pg_info',
        applyCode: 'service_pg_toss',
      },
      {
        img: 'img_kginicis.png',
        alt: 'KG Inicis',
        title: 'PG - KG 이니시스',
        tags: ['#결제/간편결제', '#가입비무료', '#통합간편결제'],
        remoteCode: 'service_pg_info',
        applyCode: 'service_pg_inicis',
      },
      {
        img: 'img_easypay.png',
        alt: 'Easy Pay',
        title: 'PG - 이지페이',
        tags: ['#결제/간편결제', '#통합간편결제'],
        remoteCode: 'service_pg_info',
        applyCode: 'service_pg_easypay',
      },
      {
        img: 'img_nicepay.png',
        alt: 'Nice Pay',
        title: 'PG - 나이스페이',
        tags: ['#결제/간편결제', '#가입비무료', '#통합간편결제'],
        remoteCode: 'service_pg_info',
        applyCode: 'service_pg_nicepay',
      },
      {
        img: 'img_galaxia.png',
        alt: '갤럭시아머니트리',
        title: 'PG - 갤럭시아머니트리',
        tags: ['#결제/간편결제', '#통합간편결제'],
        remoteCode: 'service_pg_info',
        applyCode: 'service_pg_galaxia',
      },
      {
        img: 'img_easypayov.jpg',
        alt: '이지페이(해외구매대행 선정산)',
        title: '이지페이(해외구매대행 선정산)',
        tags: ['#해외구매대행', '#결제/간편결제', '#통합간편결제'],
        remoteCode: 'service_pg_info',
        applyCode: 'service_pg_easypay_ov',
      },
      {
        img: 'img_payco.png',
        alt: '결제/간편결제 페이코',
        title: '결제/간편결제 페이코',
        tags: ['#결제/간편결제', '#가입비무료', '#필수간편결제'],
        remoteCode: 'service_payco',
      },
      {
        img: 'img_npay.png',
        alt: '네이버페이 간편결제',
        title: '네이버페이 간편결제',
        tags: ['#결제/간편결제', '#필수간편결제', '#네이버결제'],
        remoteCode: 'marketing_naver_checkout',
        needRedirection: true,
      },

      // {
      //   img: 'img_auto_deposit.png',
      //   alt: '자동 입금확인',
      //   title: '자동 입금확인',
      //   tags: ['#결제/간편결제', '#법적의무사항', '#필수서비스'],
      //   remoteCode: 'service_ebank',
      // },
    ],
  },
  {
    title: '간편로그인',
    desc: '내가 사용하고 있던\n아이디로 5초면\n로그인 끝!',
    data: [
      {
        img: 'img_payco.png',
        alt: '간편로그인 페이코',
        title: '간편로그인 페이코',
        tags: ['#간편로그인'],
        remoteCode: 'service_payco_login',
      },
      {
        img: 'img_kakao.png',
        alt: '간편로그인 카카오',
        title: '간편로그인 카카오',
        tags: ['#간편로그인'],
        remoteCode: 'service_kakao_login', // TODO
      },
      {
        img: 'img_naver.png',
        alt: '간편로그인 네이버',
        title: '간편로그인 네이버',
        tags: ['#간편로그인'],
        remoteCode: 'service_naver_login', // TODO
      },
      {
        img: 'img_facebook.png',
        alt: '간편로그인 페이스북',
        title: '간편로그인 페이스북',
        tags: ['#간편로그인'],
        remoteCode: 'service_facebook_login', // TODO
      },
    ],
  },
  {
    title: '인증/운영필수',
    desc: '법적 의무사항 준수 등\n고객정보 보호를 위한\n준비',
    data: [
      {
        img: 'img_phone_auth.png',
        alt: '휴대폰 본인 확인 서비스',
        title: '휴대폰 본인 확인 서비스',
        tags: ['#인증/운영필수', '#본인명의확인', '#회원가입'],
        remoteCode: 'service_dreamsecurity',
        applyMethod: 'POST',
      },
      {
        img: 'img_personal_info.png',
        alt: '개인정보보호배상책임',
        title: '개인정보보호배상책임',
        tags: ['#인증/운영필수', '#법적의무사항', '#필수서비스'],
        remoteCode: 'service_db_insurance',
      },
      // {
      //   img: 'img_nice.png',
      //   alt: 'NICE고객지키미',
      //   title: 'NICE고객지키미',
      //   tags: ['#인증/운영필수', '#고객정보보호', '#필수서비스'],
      //   remoteCode: 'service_nicekeeper',
      // },
    ],
  },
  // TODO : 1차 오픈스팩 아웃
  // {
  //   title: '통계/매출분석',
  //   desc: '정확한 분석과 통계로\n쇼핑몰 매출 항상',
  //   data: [
  //     {
  //       img: 'img_acecounter.png',
  //       alt: '에이스카운터',
  //       title: '에이스카운터',
  //       tags: ['#통계/매출분석', '#1개월무료', '#할인'],
  //       remoteCode: 'service_acecounter',
  //     },
  //   ],
  // },
  {
    title: '알림/고객상담',
    desc: '고객을 위한 최고의\n커뮤니케이션',
    data: [
      {
        img: 'img_080.png',
        alt: '080 수신거부 서비스',
        title: '080 수신거부 서비스',
        tags: ['#알림/고객상담', '#법률준수', '#필수서비스'],
        remoteCode: 'service_sms_refusal',
        applyMethod: 'POST',
      },
      {
        img: 'img_sms_post.png',
        alt: 'SMS 문자발송',
        title: 'SMS 문자발송',
        tags: ['#알림/고객상담', '#건당최저10원', '#충전은 미리'],
        remoteCode: 'service_sms',
      },
      {
        img: 'img_kakao_alarm.png',
        alt: '카카오 알림톡',
        title: '카카오 알림톡',
        tags: ['#알림/고객상담', '#전화번호 기반', '#대체발송'],
        remoteCode: 'service_kakao',
      },
      {
        img: 'img_mobilecti.png',
        alt: '모바일 콜센터',
        title: '모바일 콜센터',
        tags: ['#알림/고객상담', '#클라우드기반', '#재택근무'],
        remoteCode: 'service_mobilecti',
      },
      {
        img: 'img_realpacking.png',
        alt: '리얼패킹 포장촬영',
        title: '리얼패킹 포장촬영',
        tags: ['#알림/고객상담', '#고객만족도보장', '#블랙컨슈머예방'],
        remoteCode: 'service_realpacking',
      },
    ],
  },
  {
    title: '운영편의',
    desc: '어려운 업무 간편하게\n처리하고 비용도 절약',
    data: [
      {
        img: 'img_auto_account.png',
        alt: 'KB 브릿지',
        title: 'KB 브릿지',
        tags: ['#운영편의', '#금융컨설팅', '#정책자금지원'],
        remoteCode: 'service_kbbridge',
      },
      {
        img: 'img_auto_account.png',
        alt: '셀러봇캐시(통합정산관리)',
        title: '셀러봇캐시(통합정산관리)',
        tags: ['#운영편의', '#통합정산관리', '#14일무료체험'],
        remoteCode: 'service_sellerbot',
      },
      {
        img: 'img_auto_account.png',
        alt: '자동장부',
        title: '자동장부',
        tags: ['#운영편의', '#간편세무신고'],
        remoteCode: 'service_autoledger',
      },
      {
        img: 'img_kollus-live.png',
        alt: '콜러스 라이브 커머스(KLC)',
        title: '콜러스 라이브 커머스(KLC)',
        tags: ['#라이브커머스', '#실시간소통', '#채널배포'],
        remoteCode: 'service_kollus_live_commerce',
      },
      {
        img: 'img_lala-station.png',
        alt: '라라스테이션',
        title: '라라스테이션',
        tags: ['#라이브커머스', '#원스톱솔루션', '#방송기획/제작/송출'],
        remoteCode: 'lalastation',
      },
    ],
  },
  {
    title: '구매/맞춤제작',
    desc: '업계 최저가, 규격별\n최다 상품을 보유한\n차별화된 서비스',
    data: [
      {
        img: 'img_box.png',
        alt: '박스/봉투 고도박스',
        title: '박스/봉투 고도박스',
        tags: ['#구매/맞춤제작', '#NHN커머스회원특가'],
        remoteCode: 'service_box',
      },
      {
        img: 'img_box.png',
        alt: '박스/봉투 MC박스',
        title: '박스/봉투 MC박스',
        tags: ['#구매/맞춤제작'],
        remoteCode: 'service_box',
        applyCode: 'service_mc_box',
      },
    ],
  },
  {
    title: '디자인/편집/콘텐츠',
    desc: '클릭만으로 누구나 쉽게\n직접 디자인 및 의뢰가 가능',
    data: [
      {
        img: 'img_smart_editor.png',
        alt: '스마트 에디터',
        title: '스마트 에디터',
        tags: ['#디자인/편집/콘텐츠', '#상세페이지자동제작'],
        remoteCode: 'service_smarteditor',
      },
      {
        img: 'img_smart_editor.png',
        alt: '라우드소싱(디자인 의뢰)',
        title: '라우드소싱(디자인 의뢰)',
        tags: ['#디자인/편집/콘텐츠', '#디자인 콘테스트', '#합리적인 비용'],
        remoteCode: 'service_loudsourcing',
      },
      {
        img: 'img_tyle.png',
        alt: '타일(콘텐츠 제작 툴)',
        title: '타일(콘텐츠 제작 툴)',
        tags: ['#디자인/편집/콘텐츠', '#SNS마케팅', '#컨텐츠간편제작'],
        remoteCode: 'service_tyle',
      },
      {
        img: 'img_tyle.png',
        alt: '디자인소스',
        title: '디자인소스',
        tags: ['#디자인/편집/콘텐츠', '#NHN커머스회원특가', '#디지털 콘텐츠'],
        remoteCode: 'service_designsource',
      },
      {
        img: 'img_insta.png',
        alt: '인스타그램',
        title: '인스타그램 연동',
        tags: ['#인스타그램 위젯', '#SNS콘텐츠', '#인스타그램 게시글 노출'],
        remoteCode: 'service_instagram',
      },
    ],
  },
];

/**
 * 1차 오픈 시 지정한 신규 부가서비스로 노출되며 오픈 이후 GOMS 연동 시 진열 관리 가능.
 * GOMS 연동 이전까지 편의상 배열 인덱스로 관리하도록 함
 */
export const additionalMainServices: AdditionalService[] = [
  {
    title: '필수 부가서비스',
    desc: '쇼핑몰 운영 시\n꼭 필요한\n필수 서비스입니다.',
    data: [
      additionalAllServices[0].data[3],
      additionalAllServices[0].data[5],
      additionalAllServices[0].data[4],
      additionalAllServices[0].data[7],
      additionalAllServices[2].data[1],
      additionalAllServices[3].data[0],
      additionalAllServices[3].data[1],
    ],
  },
  {
    title: '신규 부가서비스',
    desc: '효율적인 운영을 위해\n새롭게 출시한\n신규 서비스입니다.',
    data: [
      additionalAllServices[1].data[0],
      additionalAllServices[0].data[8],
      additionalAllServices[3].data[2],
      additionalAllServices[4].data[3],
      additionalAllServices[4].data[4],
      additionalAllServices[0].data[3],
      additionalAllServices[0].data[4],
      additionalAllServices[0].data[5],
      additionalAllServices[0].data[6],
    ],
  },
  {
    title: '인기 부가서비스',
    desc: '쇼핑몰 운영 시\n가장 많이 사용하는\n인기 서비스입니다.',
    data: [
      additionalAllServices[1].data[3],
      additionalAllServices[1].data[2],
      additionalAllServices[1].data[1],
      additionalAllServices[6].data[5],
    ],
  },
  {
    title: '추천 부가서비스',
    desc: '운영평의 및\n매출증진을 위한\n추천 서비스입니다.',
    data: [
      additionalAllServices[0].data[7],
      additionalAllServices[3].data[0],
      additionalAllServices[3].data[1],
      additionalAllServices[3].data[2],
    ],
  },
];
